.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

body {
  background-color: #282c34;
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.fullwidth {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.noteContainer {
  width: 100%;
}

.noteImage {
  filter: invert(1);
  width: 50%;
  max-width: 400px;
}

@media only screen and (max-width: 768px) {
  .noteImage {
    width: 90%;
  }
}

.buttonBox {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.noteButton {
  cursor: pointer;
  padding: 10px 18px;
  margin: 5px;
  border-radius: 5px;
  background-color: #4a5260;
  font-size: 28px;
}

.correct {
  color: #51bf68;
}

.false {
  color: #d34d4d;
}

.active {
  border: 2px solid #2587ea
}

.inactive {
  border: 2px solid #a7a7ad
}

.chartcontainer {
  width: 50%;
  max-width: 500px;
  padding: 24px;
}

@media only screen and (max-width: 768px) {
  .chartcontainer {
    width: 100%;
  }
}
